<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";

import animationData from "@/components/widgets/nkmsrxys.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";

import Lottie from "@/components/widgets/lottie.vue";

import i18n from "../../i18n";

const { t } = i18n.global;

export default {
  page: {
    title: t("Sepet"),
    meta: [
      {
        name: "description",
        content: "",
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
  },
  watch: {
    $store: {
      handler: "test",
      deep: true,
    },
  },
  data() {
    return {
      title: t("Sepet"),
      items: [
        {
          text: "Elektro Portal",
          href: "/",
        },
        {
          text: t("Sepet"),
          active: true,
        },
      ],
      products: this.$store.getters["cart/getCart"],
      cartTotal: this.$store.getters["cart/getCartTotalPrice"],
      taxRate: 0.125,
      shippingRate: 65.0,
      discountRate: 0.15,
      quantity: "",
      currencySign: "$",
      defaultOptions: { animationData: animationData },
      defaultOptions1: { animationData: animationData1 },
      value: 2,
      value1: 1,
      value2: 1,
      selectedBelge : this.$store.getters['user/getSelectBelge'],
    };
  },

  mounted() {
    // Remove product from cart
    const removeProduct = document.getElementById("removeItemModal");
    removeProduct.addEventListener("show.bs.modal", (e) => {
      document
        .getElementById("remove-product")
        .addEventListener("click", () => {
          e.relatedTarget.closest(".product").remove();
          document.getElementById("close-modal").click();
          this.recalculateCart();
        });
    });

    setTimeout(() => {
      this.isData();
    }, 100);
  },
  methods: {
    test(){
      if(this.$store.getters['user/getSelectBelge'].docNumber != this.selectedBelge.docNumber){
        this.selectedBelge = this.$store.getters['user/getSelectBelge']
        this.products = this.$store.getters["cart/getCart"]
        this.cartTotal = this.$store.getters["cart/getCartTotalPrice"]
      }
    },
    removeProduct(index) {
      Swal.fire({
        title: t("Ürün Sepetten Çıkarılsın Mı?"),
        html: t("Ürünün sepetinizden çıkartmak istiyor musunuz?"),
        icon: "info",
        confirmButtonText: t("Sepetten Çıkar"),
        cancelButtonText: t("Vazgeç"),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("cart/removeCartProduct", { index });
        }
      });
    },
    minusValue(index) {
      if (this.products[index].quantity == 1) {
        Swal.fire({
          title: t("Ürün Sepetten Çıkarılsın Mı?"),
          html: t("Ürünün adetini 0 yapıyorsunuz, sepetten kaldırılsın mı?"),
          icon: "info",
          confirmButtonText: t("Sepetten Çıkar"),
          cancelButtonText: t("Vazgeç"),
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("cart/removeCartProduct", { index });
          }
        });
      }
      if (this.products[index].quantity > 1)
        this.$store.dispatch("cart/minusProductValue", { index });
    },
    plusValue(index) {
      this.$store.dispatch("cart/plusProductValue", { index });
    },
    updateValue(index) {
      let value = event.target.value;
      if (event.target.value > 0)
        this.$store.dispatch("cart/updateProductValue", { index, value });
      else if (event.target.value < 1) {
        event.target.value = this.products[index].quantity;
        Swal.fire({
          title: t("Ürün Sepetten Çıkarılsın Mı?"),
          html: t("Ürünün adetini 0 yapıyorsunuz, sepetten kaldırılsın mı?"),
          icon: "info",
          confirmButtonText: t("Sepetten Çıkar"),
          cancelButtonText: t("Vazgeç"),
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("cart/removeCartProduct", { index });
          } else {
            event.target.value = this.products[index].quantity;
          }
        });
      }
    },
    isData() {
      //   const plus = document.getElementsByClassName("plus");
      //   const minus = document.getElementsByClassName("minus");
      //   var product = document.getElementsByClassName("product");
      //   if (plus) {
      //     Array.prototype.forEach.call(plus, (e) => {
      //       e.addEventListener("click", (event) => {
      //         let par = event.target.closest(".input-step");
      //         par.getElementsByClassName("product-quantity")[0].value++;
      //         Array.prototype.forEach.call(product, () => {
      //           this.updateQuantity(event.target);
      //         });
      //       });
      //     });
      //   }
      //   if (minus) {
      //     Array.prototype.forEach.call(minus, (e) => {
      //       e.addEventListener("click", (event) => {
      //         let par = event.target.closest(".input-step");
      //         if (par.getElementsByClassName("product-quantity")[0].value > 0)
      //           par.getElementsByClassName("product-quantity")[0].value--;
      //         Array.prototype.forEach.call(product, () => {
      //           this.updateQuantity(event.target);
      //         });
      //       });
      //     });
      //   }
    },

    updateQuantity(quantityInput) {
      var productRow = quantityInput.closest(".product");
      var price;

      Array.prototype.forEach.call(
        productRow.getElementsByClassName("product-price"),
        (e) => {
          price = parseFloat(e.innerHTML);
        }
      );

      if (
        quantityInput.previousElementSibling &&
        quantityInput.previousElementSibling.classList.contains(
          "product-quantity"
        )
      ) {
        this.quantity = quantityInput.previousElementSibling.value;
      } else if (
        quantityInput.nextElementSibling &&
        quantityInput.nextElementSibling.classList.contains("product-quantity")
      ) {
        this.quantity = quantityInput.nextElementSibling.value;
      }
      var linePrice = price * this.quantity;
      /* Update line price display and recalc cart totals */
      Array.prototype.forEach.call(
        productRow.getElementsByClassName("product-line-price"),
        (e) => {
          e.innerHTML = linePrice.toFixed(2);
          this.recalculateCart();
        }
      );
    },
    recalculateCart() {
      var subtotal = 0;

      Array.prototype.forEach.call(
        document.getElementsByClassName("product"),
        (item) => {
          Array.prototype.forEach.call(
            item.getElementsByClassName("product-line-price"),
            (e) => {
              subtotal += parseFloat(e.innerHTML);
            }
          );
        }
      );

      /* Calculate totals */
      var tax = subtotal * this.taxRate;
      var discount = subtotal * this.discountRate;

      var shipping = subtotal > 0 ? this.shippingRate : 0;
      var total = subtotal + tax + shipping - discount;

      document.getElementById("cart-subtotal").innerHTML =
        this.currencySign + subtotal.toFixed(2);
      document.getElementById("cart-tax").innerHTML =
        this.currencySign + tax.toFixed(2);
      document.getElementById("cart-shipping").innerHTML =
        this.currencySign + shipping.toFixed(2);
      document.getElementById("cart-total").innerHTML =
        this.currencySign + total.toFixed(2);
      document.getElementById("cart-discount").innerHTML =
        "-" + this.currencySign + discount.toFixed(2);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
      <div class="col-xl-8">
        <div class="row align-items-center gy-3 mb-3">
          <div class="col-sm">
            <div>
              <h5 class="fs-15 mb-0">
                {{ $t("Sepetiniz") }} ({{ $store.getters["cart/getProductCount"] }} {{ $t("Ürün") }})
              </h5>
            </div>
          </div>
          <div class="col-sm-auto">
            <router-link
              to="/urunler"
              class="link-primary text-decoration-underline"
              >{{ $t("Alışverişe Devam Et") }}</router-link
            >
          </div>
        </div>

        <div
          class="card product"
          v-for="(product, index) in products"
          :key="product.id"
        >
          <div class="card-body">
            <div class="row gy-3">
              <div class="col-sm-auto">
                <div class="avatar-lg bg-light rounded p-1">
                  <img :src="product.image" alt="" class="img-fluid d-block" />
                </div>
              </div>
              <div class="col-sm">
                <h5 class="fs-15 text-truncate">
                  <router-link to="/ecommerce/product-detail" class="text-dark">
                    {{ product.name }}</router-link
                  >
                </h5>
                <ul class="list-inline text-muted">
                  <li class="list-inline-item">
                    {{ $t("Ürün Kodu") }} :
                    <span class="fw-medium">{{ product.productCode }}</span>
                  </li>
                  <li class="list-inline-item">
                    {{ $t("Marka") }} : <span class="fw-medium">{{ product.brand }}</span>
                  </li>
                </ul>

                <div class="input-step">
                  <button
                    type="button"
                    class="minus"
                    @click="minusValue(index)"
                  >
                    –
                  </button>
                  <input
                    type="number"
                    class="product-quantity"
                    :value="product.quantity"
                    @input="updateValue(index)"
                    min="0"
                    max="100"
                  />
                  <button type="button" class="plus" @click="plusValue(index)">
                    +
                  </button>
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="text-lg-end">
                  <p class="text-muted mb-1">{{ $t("Site Fiyatı") }}:</p>
                  <h5 class="fs-15">
                    <span id="ticket_price" class="product-price">{{
                      $filters.currency(product.price)
                    }}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <!-- card body -->
          <div class="card-footer">
            <div class="row align-items-center gy-3">
              <div class="col-sm">
                <div class="d-flex flex-wrap my-n1">
                  <div>
                    <a
                      type="button"
                      @click="removeProduct(index)"
                      class="d-block text-body p-1 px-2"
                    >
                      <i
                        class="ri-delete-bin-fill text-muted align-bottom me-1"
                      ></i>
                      {{ $t("Sepetten Çıkar") }}
                    </a>
                  </div>
                  <div>
                    <a href="#" class="d-block text-body p-1 px-2"
                      ><i class="ri-star-fill text-muted align-bottom me-1"></i>
                      Add Wishlist</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="d-flex align-items-center gap-2 text-muted">
                  <div>{{ $t("Toplam") }} :</div>
                  <h5 class="fs-15 mb-0">
                    <span class="product-line-price">{{
                      $filters.currency(product.quantity * product.price)
                    }}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <!-- end card footer -->
        </div>
        <!-- end card -->

        <div class="text-end mb-4">
          <router-link
            to="/teklif-iste"
            class="btn btn-success btn-label right ms-auto"
            ><i
              class="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"
            ></i>
            {{ $t("teklif-iste") }}</router-link
          >
        </div>
      </div>
      <!-- end col -->

      <div class="col-xl-4">
        <div class="sticky-side-div">
          <div class="card">
            <div class="card-header border-bottom-dashed">
              <h5 class="card-title mb-0">{{ $t("Sipariş Özeti") }}</h5>
            </div>
            <div class="card-body pt-2">
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>{{ $t("Ara Toplam") }} :</td>
                      <td class="text-end" id="cart-subtotal">
                        {{
                          $filters.currency(
                            $store.getters["cart/getCartTotalPrice"]
                          )
                        }}
                      </td>
                    </tr>
                    <tr class="table-active">
                      <th>{{ $t("Toplam") }} :</th>
                      <td class="text-end">
                        <span class="fw-semibold" id="cart-total">
                          {{
                            $filters.currency(
                              $store.getters["cart/getCartTotalPrice"]
                            )
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
          </div>

          <div class="alert border-dashed alert-danger" role="alert">
            <div class="d-flex align-items-center">
              <lottie
                colors="primary:#121331,secondary:#f06548"
                :options="defaultOptions"
                :height="80"
                :width="80"
                style="margin: 0px"
              />
              <div class="ms-2">
                <h5 class="fs-14 text-danger fw-semibold">
                  Buying for a loved one?
                </h5>
                <p class="text-black mb-1">
                  Gift wrap and personalised message on card, <br />Only for
                  <span class="fw-semibold">$9.99</span> USD
                </p>
                <button
                  type="button"
                  class="btn ps-0 btn-sm btn-link text-danger text-uppercase"
                >
                  Add Gift Wrap
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end stickey -->
      </div>
    </div>
    <!-- end row -->
    <!-- removeItemModal -->
    <div
      id="removeItemModal"
      class="modal fade zoomIn"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <lottie
                colors="primary:#ffc84b,secondary:#f06548"
                :options="defaultOptions"
                :height="100"
                :width="100"
              />
              <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you Sure ?</h4>
                <p class="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Product ?
                </p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                class="btn w-sm btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn w-sm btn-danger"
                id="remove-product"
              >
                Yes, Delete It!
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </Layout>
</template>
<style scoped>
.avatar-lg {
  min-height: 6rem;
  height: auto;
}
</style>